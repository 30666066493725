//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    InvitationList: () => import("./invitation-list"),
    TeamInfo: () => import("./team-info"),
    Apply: () => import("./apply"),
    Scheduling: () => import("./scheduling"),
    RuleSetting: () => import("@/userview/process-rulesver"),
    CreateUser: () => import("./create-user"),
    HourlyWage: () => import("./hourlywage-modal.vue"),
    UpAccount: () => import("@/components/uploadAccount"),
  },
  props: {
    //选择的团队
    selRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      viewType: 1,
      infoData: null, //详细信息
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    checkFunction(func) {
      if (!this.infoData || !this.infoData.Teamdata.Functions) {
        return false;
      }
      return this.infoData.Teamdata.Functions.indexOf(func) != -1;
    },
    /**
     * 管理员添加成员
     */
    handleAddUser() {
      this.$modal.show("createUser");
    },
    /**
     * 管理员批量添加成员
     */
    handleBatchAddUser() {
      this.$modal.show("loadXls");
    },
    BatchAddUserSuccess() {
      this.getData();
    },
    /**
     * 邀请列表清空按钮
     */
    handleInvitationDel() {
      this.$refs.InvitationList.handleDel();
    },
    /**
     * 邀请列表的按钮
     */
    invitationBtn(val) {
      this.$refs.InvitationList.handleInv(null, val);
    },
    /**
     * 视图切换
     */
    handleViewChange(val) {
      this.viewType = val;
      if (this.viewType == 1) {
        this.getData();
      }
    },
    /**
     * 获取团队信息
     */
    getData() {
      this.$http
        .get("/Teams/TeamManagementDetail.ashx", {
          params: { teamId: this.selRow.Id },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.infoData = resp.data;
          }
        });
    },
  },
};
